import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import { apiCall, callApi } from '../common/operations';
import { getErrorMessage } from '../../api/network';
import { notificationApi } from '../../api';
import { NotificationType } from 'shovel-lib/types';
import { openManageTaskDialog, openTasksModal } from '../task/actions';
import { initFcmMessaging, isFcmMessagingSupported } from '../../config/firebaseInitializer';
import { notify } from '@components/common/snackbarNotifications/Notifications';
import { Dispatch } from 'redux';
import { getType } from 'typesafe-actions';
import { taskApi } from 'shovel-lib';
import * as semesterSelectors from '../semester/selectors';
import { push } from 'connected-react-router';
import { goToHomeLink } from '@components/navigation/navigationHelpers';

function* getNotifications() {
  const apiCall = call(notificationApi.getNotifications);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getNotifications.success(response.data));
  } else {
    yield put(actions.getNotifications.failure(getErrorMessage(response)));
  }
}

function* countUnseenNotifications() {
  const apiCall = call(notificationApi.countUnseenNotifications);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.countUnseenNotifications.success(response.data));
  } else {
    yield put(actions.countUnseenNotifications.failure(getErrorMessage(response)));
  }
}

function* clearNotifications() {
  const apiCall = call(notificationApi.clearNotifications);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.clearNotifications.success());
  } else {
    yield put(actions.clearNotifications.failure(getErrorMessage(response)));
  }
}

function* deleteNotification(action: ReturnType<typeof actions.deleteNotification.request>) {
  const apiCall = call(notificationApi.deleteNotification, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.deleteNotification.success(action.payload));
  } else {
    yield put(actions.deleteNotification.failure(getErrorMessage(response)));
  }
}

function* openNotification(action: ReturnType<typeof actions.openNotification>) {
  const notification = action.payload;
  switch (notification.type) {
    case NotificationType.PLANNED_TASK_STARTS:
      const semesterId = yield select(semesterSelectors.getId);
      yield put(push(goToHomeLink(semesterId)));
      break;
    case NotificationType.PLANNED_TASK_ENDS:
    case NotificationType.TASK_OVERDUE: {
      const { taskId, taskType: type } = notification.payload;
      yield put(openManageTaskDialog({ taskId, type }));
      break;
    }
    case NotificationType.TASKS_SHOULD_BE_WORKING_ON: {
      const semesterId = yield select(semesterSelectors.getId);
      const taskIds =
        // @ts-ignore
        typeof notification.payload.taskIds === 'string'
          ? (notification.payload.taskIds as string).split(',').map(id => parseInt(id))
          : notification.payload.taskIds;
      const { ok, ...response } = yield apiCall(taskApi.getTasksByIds, semesterId, taskIds);
      if (ok) {
        yield put(openTasksModal(response.data));
      }
      break;
    }
  }
}

export function* watchForNotifications(dispatch: Dispatch) {
  if (!isFcmMessagingSupported) return;

  while (true) {
    yield take(getType(actions.startNotificationsListener));

    const messaging = yield call(initFcmMessaging);

    messaging.onMessage(payload => {
      console.log('Message received. ', payload);
      const { title, body } = payload.notification;
      const { type, ...data } = payload.data;
      notify({
        title,
        body,
        onClick: () => dispatch({ type: getType(actions.openNotification), payload: { type: type, payload: data } })
      });
      dispatch({ type: getType(actions.notificationReceived) });
    });
  }
}

export default function*(dispatch: Dispatch) {
  yield all([
    watchForNotifications(dispatch),
    takeLatest(actions.getNotifications.request, getNotifications),
    takeLatest(actions.clearNotifications.request, clearNotifications),
    takeLatest(actions.countUnseenNotifications.request, countUnseenNotifications),
    takeLatest(actions.deleteNotification.request, deleteNotification),
    takeLatest(actions.openNotification, openNotification)
  ]);
}
