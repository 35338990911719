import React, { PureComponent } from 'react';
import { Formats, MembershipStatus, MembershipType, PaymentProcessor, SubscriptionInfo } from 'shovel-lib/types';
import { formatMomentDateAs, now, timeDiffInDays, toMomentDate } from 'shovel-lib/utils/timeUtils';
import t from '../../../i18n/t';
import { ExtraLargeText, MediumText, RegularText } from '../../../utils/typography';
import colors from '../../../utils/colors';
import { openConfirmationDialog } from '../../../state/common/actions';
import { cancelSubscription, openHelpUsDialog, reactivateSubscription } from '../../../state/settings/actions';
import { deletingReasons } from '../../../utils/settingsUtils';
import { settingsActions } from '../../../state/settings';
import { Column, FlexColumn } from '../../common/layoutUtils';
import { membershipApi } from 'shovel-lib';
import { resetTrialFromDashboard } from '../../../state/auth/actions';
import { RootState } from '../../../state/rootReducer';
import { getSubscriptionInfo } from '../../../state/settings/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import TextLink from '../../common/TextLink';
import Button from '../../common/buttons/Button';
import timeboxing from '../../../assets/images/Timeboxing.png';
import ManageStripeBilling from './ManageStripeBilling';
import storage from '@utils/storage';

type Props = {
  membership?: SubscriptionInfo;
};

type PropsFromDispatch = {
  cancelSubscription: typeof cancelSubscription.request;
  reactivateSubscription: typeof reactivateSubscription.request;
  openConfirmationDialog: typeof openConfirmationDialog;
  openHelpUsDialog: typeof openHelpUsDialog;
  resetTrialFromDashboard: typeof resetTrialFromDashboard.request;
};

const membershipText = {
  [MembershipType.MONTHLY]: `${t.MONTHLY} (1 ${t.MONTH})`, // ${t.MONTH_PRICE}`,
  [MembershipType.SEMI_ANNUAL]: `${t.SEMI_ANNUAL} (6 ${t.MONTHS})`, // ${t.HALF_YEAR_PRICE}`,
  [MembershipType.ANNUAL]: `${t.ANNUAL} (1 ${t.YEAR})`, // ${t.YEAR_PRICE}`,
  [MembershipType.FREE_TRIAL]: t.NO_PLAN_TEXT // ${t.YEAR_PRICE}`,
};

/**
 * This component is mounted only for subscription, free trial and LIFETIME membership
 * OLD_USER, INVITED and ORGANIZATION will not see it
 *
 * Actions based on membership type and current state:
 *
 * Free trial not-subscribed - subscribe to plan with entering card
 *  - with access - charge at the end of trial period, becomes Subscribed Trial user
 *  - expired - charge immediately, becomes Subscribed Active user
 *
 * Subscribed
 *  - Trial - upgrade/downgrade (charge immediately since we are using customer portal), cancel, change card
 *  - Active
 *    - with access - upgrade/downgrade (charge at the end of current cycle if downgrade, charge pro-rated fee immediately if upgrade), cancel, change card
 *    - expired (probably failed payment) - retry charge, change card
 *  - Canceled
 *    - with access - reactivate
 *    - expired - subscribe to plan without entering card (charge immediately), change card
 *
 * Stripe implementation:
 * - Subscribing to plan is implemented through Stripe Checkout form
 * - Cancelling and reactivation are implemented manually (our backend updates subscription on Stripe)
 * - Upgrade/downgrade and change card will go through Stripe's Customer Portal
 */
class Membership extends PureComponent<PropsFromDispatch & Props> {
  componentDidMount() {
    if (this.props.membership?.type === MembershipType.LIFETIME) return;
  }

  cancelSubscription = async (data: string) => {
    const { cancelSubscription, membership } = this.props;
    cancelSubscription({ cancellationReasons: data, paymentProcessor: membership!.paymentProcessor! });
  };

  reactivateSubscription = async () => {
    const { reactivateSubscription, membership } = this.props;
    reactivateSubscription({ paymentProcessor: membership!.paymentProcessor! });
  };

  openHelpUsDialog = () => {
    this.props.openHelpUsDialog({
      confirmAction: this.cancelSubscription,
      loadingAction: settingsActions.cancelSubscription,
      options: deletingReasons,
      message: t.CANCEL_PRICING_PLAN_MSG
    });
  };

  confirmCancellation = () => {
    this.props.openConfirmationDialog({
      title: t.CANCEL_SUBSCRIPTION,
      action: this.openHelpUsDialog,
      message: t.CANCEL_SUBSCRIPTION_TEXT,
      understandCheck: true
    });
  };

  confirmReactivation = () => {
    this.props.openConfirmationDialog({
      title: t.REACTIVATE_SUBSCRIPTION,
      loadingAction: settingsActions.reactivateSubscription,
      action: this.reactivateSubscription,
      message: t.REACTIVATE_SUBSCRIPTION_TEXT,
      understandCheck: true
    });
  };

  manageBilling = async () => {
    const response = await membershipApi.stripeCreateCustomerPortalSession();
    if (response.ok) {
      window.location.href = response.data!.url;
    }
  };

  render() {
    const { membership, resetTrialFromDashboard } = this.props;

    if (!membership) return null;

    if (membership.type === MembershipType.LIFETIME) {
      return (
        <LifetimeContainer>
          <Title strong>{t.YOUR_PLAN}:</Title>
          <RegularText>{t.LIFETIME}</RegularText>
          <LifetimeImageContainer>
            <div>
              <ExtraLargeText strong>{t.LIFETIME_IMAGE_TEXT}</ExtraLargeText>
              <TimeboxingImage src={timeboxing} />
            </div>
          </LifetimeImageContainer>
        </LifetimeContainer>
      );
    }

    if (!membership.accessUntil) return null;

    const { type, canceled, accessUntil, trial } = membership;

    const currentTime = now();

    const hasAccess = toMomentDate(accessUntil).isAfter(currentTime);

    const isExpired = membership.status === MembershipStatus.EXPIRED;

    if (isExpired) {
      return (
        <Container>
          <Heading>
            <RegularText strong>{t.EXPIRED_SUBSCRIPTION}</RegularText>
            <MediumText color={colors.negative} strong>
              {t.EXPIRED_SUBSCRIPTION_TEXT_INITIAL_PAYMENT_FAILED}
            </MediumText>
          </Heading>
          <Title strong>{t.YOUR_PLAN}:</Title>
          <RegularText>{t.NO_PLAN_TEXT}</RegularText>
          {/* {membership.allowResetTrial && (
            <ResetTrialButton onClick={() => resetTrialFromDashboard()}>{t.RESTART_TRIAL}</ResetTrialButton>
          )} */}
          {this.renderDashboard()}
        </Container>
      );
    }

    if (canceled) {
      const isDeleted = membership.status === MembershipStatus.DELETED;

      return (
        <Container>
          {(isDeleted || !hasAccess) && (
            <Heading>
              <RegularText strong>{t.EXPIRED_SUBSCRIPTION}</RegularText>
              <MediumText color={colors.negative} strong>
                {t.EXPIRED_SUBSCRIPTION_TEXT_CANCELED}
              </MediumText>
            </Heading>
          )}
          <Title strong>{t.YOUR_PLAN}:</Title>
          {!isDeleted && hasAccess ? (
            <RegularText>
              {membershipText[type]} - {t.CANCELED}
            </RegularText>
          ) : (
            <RegularText>{t.NO_PLAN_TEXT}</RegularText>
          )}
          {!isDeleted && hasAccess && <ReactivatePlan onClick={this.confirmReactivation}>{t.REACTIVATE}</ReactivatePlan>}
          {/* {membership.allowResetTrial && (
            <ResetTrialButton onClick={() => resetTrialFromDashboard()}>{t.RESTART_TRIAL}</ResetTrialButton>
          )} */}
          {!isDeleted && (
            <Column>
              <Title strong>{t.ACCESS_UNTIL}</Title>
              <RegularText>{formatMomentDateAs(toMomentDate(accessUntil), Formats.LONG_DATE)}</RegularText>
            </Column>
          )}
          {this.renderDashboard()}
        </Container>
      );
    }

    let planInfo;

    const isFreeTrial = type === MembershipType.FREE_TRIAL;

    if (isFreeTrial || trial) {
      if (hasAccess) {
        const daysLeft = Math.ceil(timeDiffInDays(currentTime.toDate(), accessUntil));
        planInfo = `${membershipText[type]} - ${t.DAYS_OF_FREE_TRIAL_LEFT(daysLeft)}`;
      } else {
        planInfo = `${membershipText[type]} - ${t.TRIAL_EXPIRED}`;
      }
    } else {
      planInfo = membershipText[type];
    }

    if (isFreeTrial) {
      return (
        <Container>
          <Title strong>{t.YOUR_PLAN}:</Title>
          <RegularText>{planInfo}</RegularText>
          {membership.allowResetTrial && (
            <ResetTrialButton onClick={() => resetTrialFromDashboard()}>{t.RESTART_TRIAL}</ResetTrialButton>
          )}
          {/* <SubscribeToPlan tier={membership.tier} /> */}
          <Resubscribe />
        </Container>
      );
    }

    return (
      <Container>
        {!hasAccess && (
          <Heading>
            <RegularText strong>{t.EXPIRED_SUBSCRIPTION}</RegularText>
            <MediumText color={colors.negative} strong>
              {t.EXPIRED_SUBSCRIPTION_TEXT_FAILED_PAYMENT}
            </MediumText>
          </Heading>
        )}
        <Title strong>{t.YOUR_PLAN}:</Title>
        <RegularText>{planInfo}</RegularText>
        <>
          <CancelPlan onClick={this.confirmCancellation}>{t.CANCEL}</CancelPlan>
          <Title strong>{t.NEXT_BILLING}</Title>
          <RegularText>{formatMomentDateAs(toMomentDate(accessUntil), Formats.LONG_DATE)}</RegularText>
          {this.renderDashboard()}
        </>
      </Container>
    );
  }

  renderDashboard = () => {
    const { membership } = this.props;

    if (!membership) return null;

    const isDeleted = membership.status === MembershipStatus.DELETED;
    const isExpired = membership.status === MembershipStatus.EXPIRED;
    const isDeletedOrExpired = isDeleted || isExpired;

    if (membership.paymentProcessor === PaymentProcessor.STRIPE) {
      return (
        <Column style={{ alignItems: 'flex-start' }}>
          {!isDeletedOrExpired && (
            <>
              <ManageStripeBilling />
              {/* {!['BUNDLE', 'BUNDLE_PARENT', 'STUDENT_UPFRONT_ANNUAL', 'STUDENT_UPFRONT_MONTHLY'].includes(
                // @ts-ignore
                membership.purchaseType
              ) && <SwitchToLifetimeForm paymentProcessor={PaymentProcessor.STRIPE} tier={membership.tier} />} */}
            </>
          )}
          {/* {isDeletedOrExpired && <SubscribeToPlan tier={membership.tier} />} */}
          {isDeletedOrExpired && <Resubscribe />}
        </Column>
      );
    }

    if (membership.paymentProcessor === PaymentProcessor.BRAINTREE) {
      return (
        <Column style={{ alignItems: 'flex-start' }}>
          {/* {isDeletedOrExpired && <SubscribeToPlan tier={membership.tier} />} */}
          {isDeletedOrExpired && <Resubscribe />}
          {/* {!isDeletedOrExpired && (
            <SwitchToLifetimeForm paymentProcessor={PaymentProcessor.BRAINTREE} tier={membership.tier} />
          )} */}
        </Column>
      );
    }

    return null;
  };
}

const Resubscribe = () => {
  const userId = storage.getUserId();

  if (!userId) {
    console.error('userId is missing');
    return null;
  }

  const goToResubscribePage = () => {
    const link = `https://shovelapp.io/resubscribe/?user=${userId}`;
    window.open(link, '_blank');
  };

  return (
    <Button style={{ marginTop: '20px' }} onClick={goToResubscribePage}>
      {t.RESUBSCRIBE}
    </Button>
  );
};

const mapStateToProps = (state: RootState) => ({
  membership: getSubscriptionInfo(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      cancelSubscription: cancelSubscription.request,
      reactivateSubscription: reactivateSubscription.request,
      openConfirmationDialog: openConfirmationDialog,
      openHelpUsDialog: openHelpUsDialog,
      resetTrialFromDashboard: resetTrialFromDashboard.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Membership);

const LifetimeContainer = styled(FlexColumn)`
  padding: 15px 20px 200px 20px;
  overflow: auto;
`;

const Container = styled.div`
  padding: 15px 20px 200px 20px;
  overflow: auto;
`;

const Title = styled(RegularText)`
  display: block;
  margin-top: 15px;
  text-transform: uppercase;
`;

const ReactivatePlan = styled(Button)`
  margin-top: 10px;
`;

const CancelPlan = styled(TextLink)`
  display: inline-block;
  margin-left: 10px;
`;

const Heading = styled(Column)`
  padding-top: 5px;
  max-width: 650px;

  ${RegularText} {
    margin-bottom: 15px;
  }

  ${MediumText} {
  }
`;

const ResetTrialButton = styled(Button)`
  margin-top: 10px;
`;

const LifetimeImageContainer = styled(FlexColumn)`
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  > div {
    max-width: 100%;
    max-height: 100%;
  }
`;

const TimeboxingImage = styled.img`
  margin-top: 50px;
  margin-bottom: 300px;
  width: 100%;
`;
